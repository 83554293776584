import React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import NotFoundIcon from '../assets/svg/notFound.inline.svg'

const NotFoundPage = data => {
    return (
        <Layout isLang={true}>
            <Seo title='404: Страница не найдена' meta={{ metaDesc: 'Страница не найдена' }} />
            <div className='global-container' style={{ textAlign: 'center' }}>
                {/*<h1>Ну гешиниа, ме шентан вар!</h1>*/}
                <p className='text-not-found'>К сожалению, такой страницы нет.<br/>
                    Зато у нас есть другие страницы — например <a href='/'>главная</a> или <a href='/menu'>меню</a>.</p>
                {/*<NotFoundIcon />*/}
            </div>
        </Layout>
    )
}

export default NotFoundPage
